import React from 'react'
import styles from './Achievements.module.scss'

interface Props {}

export const Achievements: React.FC<Props> = () => {
    return (
        <div className={styles.container}>
            <div className={styles.achievements}>
                <div className={styles.achievement}>
                    <img
                        className={styles.utilizingImage}
                        src="/images/PDFTemplate/SecondTemplatePage/weProcessIcon.svg"
                        alt=""
                    />
                    <p>We process over 1.8M ad auctions per second</p>
                </div>

                <div className={styles.achievement}>
                    <img
                        className={styles.utilizingImage}
                        src="/images/PDFTemplate/SecondTemplatePage/weAnalyzeIcon.svg"
                        alt=""
                    />
                    <p>We analyze 60 data-points before serving each impression</p>
                </div>

                <div className={styles.achievement}>
                    <img
                        className={styles.utilizingImage}
                        src="/images/PDFTemplate/SecondTemplatePage/ourGlobalReachIcon.svg"
                        alt=""
                    />
                    <p>Our global reach allows us to launch a campaign in almost any market within hours</p>
                </div>
            </div>
        </div>
    )
}
